import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group';

export const AnimationWrapper = styled.div<{ $state: TransitionStatus }>`
  width: 100%;
  animation: ${({ $state }) => {
    if ($state === 'entering') {
      return 'fadein 500ms both';
    }
    if ($state === 'exiting') {
      return 'grow reverse 450ms both';
    }
    return '';
  }};

  opacity: ${({ $state }) => {
    if ($state === 'exited') {
      return '0';
    }
    return '';
  }};

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
